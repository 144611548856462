import React from "react";
import _ from "lodash";
import classnames from "classnames";
import Joi from 'joi';
import { Typography } from "@material-ui/core";

import styles from "./index.module.scss";
import AppContainer from "../AppContainer";
import Card from "../Card";
import { InputGroup, FullNameInput, EmailInput, TextInput } from "../Inputs";
import Button from "../Button";
import CircleIcon from "../icons/Circle.svg";
import DairyFreeIcon from "../icons/DairyFree.svg";
import GlutenFreeIcon from "../icons/GlutenFree.svg";
import VeganIcon from "../icons/Vegan.svg";
import VegeterianIcon from "../icons/Vegeterian.svg";
import EmptyProfileIcon from "../icons/EmptyProfile.svg";
import Avatar from "./avatar";
import {
  getParams,
  setParams,
  removeParams,
  navigateTo,
} from "../../utils/location";
import Loader from "../Loader";
import PhotoUploader from "../PhotoUploader";
import BirthdayDatePicker from "../BirthdayDatePicker";
import BirthdayIcon from "../icons/Birthday.svg";
import moment from "../../utils/moment-timezone-with-data-2012-2022";
import PreferredLocations from "../PreferredLocations";

//User details
const NAME = "name";
const EMAIL = "email";
const BIRTHDAY = "birthday";
const PREFERENCES = "preferences";
const PREFERRED_LOCATIONS = "preferredLocations";

const FIELDS = [NAME, EMAIL, BIRTHDAY, PREFERRED_LOCATIONS];
const MANDATORY_FIELDS = [NAME, EMAIL];

const TASTE_PREFERENCE_TO_LABEL = {
  dairyfree: "Dairy Free",
  glutenfree: "Gluten Free",
  vegan: "Vegan",
  vegeterian: "Vegetarian",
};

const TASTE_PREFERENCE_TO_ICON = {
  dairyfree: DairyFreeIcon,
  glutenfree: GlutenFreeIcon,
  vegan: VeganIcon,
  vegeterian: VegeterianIcon,
};

//TODO it can be moved under PreferredLocations component later
const preferredLocationsSchema = Joi.array()
  .items(Joi.string().required())
  .label(PREFERRED_LOCATIONS)
  .required();

const labelTastePreference = (tastePreference) =>
  TASTE_PREFERENCE_TO_LABEL[tastePreference] || tastePreference;

const renderTastePreferenceIcon = (tastePreference) => {
  const IconComponent = TASTE_PREFERENCE_TO_ICON[tastePreference];
  return <IconComponent className={styles.ForegroundIcon} />;
};

const errorkey = (key) => `${key}_ERROR`;

const InitialFormState = FIELDS.reduce(
  (o, key) => ({ ...o, [key]: null, [errorkey(key)]: null }),
  {},
);

export default class extends React.Component {
  fieldsRefs = {};
  state = {
    ...InitialFormState,
    preview: null,
    granted: false,
    birthdaySetByUser: false,
    birthday: {},
    preferredLocations: [],
  };

  componentWillReceiveProps(nextProps) {
    if (
      this.props.user.userDetails.loading &&
      !nextProps.user.userDetails.loading &&
      !_.isEmpty(nextProps.user.userDetails.data)
    ) {
      const userDetails = nextProps.user.userDetails.data;

      this.setState({
        ...InitialFormState,
        preview: null,
        name: userDetails.name,
        email: userDetails.email,
        checkedPreferences: userDetails[PREFERENCES]
          ? _.keys(
              _.pickBy(userDetails[PREFERENCES], (val, key) => val === "true"),
            )
          : [],
      });

      if (userDetails.birthday) {
        this.setState({ birthday: userDetails.birthday });
      }

      if (userDetails.preferredLocations) {
        this.setState({ preferredLocations: userDetails.preferredLocations });
      }
    }
  }

  getTastePreferencesOptions = () => {
    const {
      pageContext: {
        appStyles: { tastePrefrencesOptions },
      },
    } = this.props;
  };

  componentWillMount() {
    if (!this.props.user.loggedIn && typeof window != "undefined") {
      navigateTo("/");
    }
  }

  componentDidMount() {
    if (this.props.user.userDetails.data) {
      const userDetails = this.props.user.userDetails.data;

      this.setState({
        ...InitialFormState,
        preview: null,
        name: userDetails.name,
        email: userDetails.email,
        checkedPreferences: userDetails[PREFERENCES]
          ? _.keys(
              _.pickBy(userDetails[PREFERENCES], (val, key) => val === "true"),
            )
          : [],
      });

      if (userDetails.birthday) {
        this.setState({ birthday: userDetails.birthday });
      }

      if (userDetails.preferredLocations) {
        this.setState({ preferredLocations: userDetails.preferredLocations });
      }
    }

    this.props.loadUserDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !prevProps.user.saveProfileState.sent &&
      this.props.user.saveProfileState.sent
    ) {
      this.nextPageTransition = setTimeout(() => {
        navigateTo("/");
        this.props.resetSaveProfile();
      }, 300);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.nextPageTransition);
  }

  saveProfile = () => {
    const { user, appStyles } = this.props;

    const fieldsToValidate = MANDATORY_FIELDS;
    Promise.all(_.compact([
      ..._.map(
        fieldsToValidate,
        (field) =>
          new Promise((resolve) =>
            this.fieldsRefs[field].validate((error, value) =>
              resolve({ field, error, value })
            )
          )
      ),
      // joi validation for preferredLocations
      _.get(
        this.props.pageContext,
        "businessAppConfiguration.requirePreferredLocationsOnSignup"
      ) && {
        error: null,
        field: PREFERRED_LOCATIONS,
        ...preferredLocationsSchema.validate(this.state.preferredLocations),
      },
    ])).then((results) => {
      this.setState(
        _.reduce(
          results,
          (state, result) => ({
            ...state,
            [result.field]: result.value,
            [errorkey(result.field)]: result.error,
          }),
          {}
        ),
        () => {
          if (!_.every(fieldsToValidate, this.isValidField)) {
            return console.log("invalid fields");
          } else {
            const userDetailsToSave = {};

            if (this.state.preview) {
              userDetailsToSave.imageUrl = this.state.preview;
            }

            if (
              !this.state.birthdaySetByUser &&
              user.userDetails.data.birthday
            ) {
              userDetailsToSave.birthday = user.userDetails.data.birthday;
            } else {
              if (
                this.state.birthday &&
                _.get(this.state.birthday, "day") &&
                _.get(this.state.birthday, "month") &&
                _.get(this.state.birthday, "year")
              ) {
                userDetailsToSave.birthday = _.clone(this.state.birthday);
              }
            }

            const preferences = _.reduce(
              appStyles.tastePrefrencesOptions,
              (obj, preference) => {
                if (this.state.checkedPreferences.includes(preference)) {
                  obj[preference] = "true";
                } else {
                  obj[preference] = "false";
                }
                return obj;
              },
              {}
            );

            if (
              this.state.name !== user.userDetails.data.name ||
              this.state.email !== user.userDetails.data.email ||
              !_.isEqual(preferences, user.userDetails.data.preferences)
            ) {
              userDetailsToSave.name = !_.isEmpty(this.state.name)
                ? this.state.name
                : user.userDetails.data.name;
              userDetailsToSave.email = !_.isEmpty(this.state.email)
                ? this.state.email
                : user.userDetails.data.email;
              userDetailsToSave.preferences = preferences;
            }

            if (
              !_.isEqual(
                this.state.preferredLocations,
                user.userDetails.data.preferredLocations
              )
            ) {
              userDetailsToSave.preferredLocations = [
                ...this.state.preferredLocations,
              ];
            }

            this.props.saveProfile(userDetailsToSave);
          }
        }
      );
    });
  };

  onChange = (checked) => {
    this.setState({ checkedPreferences: checked });
  };

  hideSaveButton = () => {
    const { user } = this.props;
    if (!user.loggedIn) return true;

    const preferencesUpdate = _.isEmpty(user.userDetails.data.preferences)
      ? _.isEmpty(this.state.checkedPreferences)
      : _.isEqual(
          this.state.checkedPreferences,
          _.keys(
            _.pickBy(
              user.userDetails.data.preferences,
              (val, key) => val === "true",
            ),
          ),
        );

    const isBirthdayNotUpated = () => {
      if (_.get(this.fieldsRefs, BIRTHDAY)) {
        const { day, month, year } = this.state.birthday || {};
        const userHasNotEnteredBirthday = !day && !month && !year;
        if (userHasNotEnteredBirthday) {
          return true;
        }
        return false;
      }
      return true;
    };

    const isBirthdayFieldsMissing = () => {
      if (_.get(this.fieldsRefs, BIRTHDAY)) {
        const { day, month, year } = this.state.birthday || {};
        const userEnteredBirthdayAndFieldMissing = !day || !month || !year;
        if (userEnteredBirthdayAndFieldMissing) {
          return true;
        }
        return false;
      }
      return true;
    };

    const hasInvalidField = _.some(FIELDS, (field) => {
      if (field === BIRTHDAY) {
        return isBirthdayNotUpated()
          ? false
          : isBirthdayFieldsMissing()
          ? true
          : false;
      }

      if (field === PREFERRED_LOCATIONS) {
        return (
          _.get(
            this.props.pageContext,
            "businessAppConfiguration.requirePreferredLocationsOnSignup"
          ) && _.isEmpty(this.state.preferredLocations)
        );
      }

      return !this.isValidField(field);
    });

    const noFieldUpdate = _.every(FIELDS, (field) => {
      if (field === BIRTHDAY) {
        return isBirthdayNotUpated();
      }
      return this.state[field] === user.userDetails.data[field];
    });

    return (
      !user.profileSignUpFlowMode &&
      (noFieldUpdate || hasInvalidField) &&
      preferencesUpdate &&
      !user.saveProfileState.sent &&
      !this.state.preview
    );
  };

  onInputError = (field) => (error) =>
    this.setState({ [errorkey(field)]: error });

  onInputValid = (field) => (value) => {
    this.setState({ [errorkey(field)]: null, [field]: value });
  };

  registerInput = (field) => (ref) => {
    this.fieldsRefs = { ...this.fieldsRefs, [field]: ref };
  };

  isValidField = (field) => !this.state[errorkey(field)] && !!this.state[field];

  handleChangeToCamera = (to) => () => {
    const { location } = this.props;
    this.setState({ preview: null }, () => {
      console.log(
        "requset camera permissions:",
        window.requestCameraPermissions,
      );
      if (window.isNativeApp && window.requestCameraPermissions) {
        const requestCameraPermissions = (success, error) => {
          window.requestCameraPermissionsError = error;
          window.requestCameraPermissionsSuccess = success;
          window.postMessage(
            JSON.stringify({ type: "REQUEST_CAMERA_PERMISSIONS" }),
          );
        };

        requestCameraPermissions(
          () => {
            this.setState({ granted: true }, () => {
              navigateTo(setParams(location, { [to]: true }));
            });
          },
          () => {
            // alert('Please enable camera permissions in settings');
          },
        );
      } else {
        this.setState({ granted: true }, () => {
          navigateTo(setParams(location, { [to]: true }));
        });
      }
    });
  };

  applyPhotoEdit = () => {
    const { location } = this.props;
    navigateTo(removeParams(location, "changePhoto"));
  };

  onClosePicture = () => {
    this.setState({ preview: null });
  };

  onCropPicture = (preview) => {
    this.setState({ preview });
  };

  uploadImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        this.setState({ preview: reader.result });
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  getInputPropsFor = (inputId, refKey = "refEl") => ({
    [refKey]: this.registerInput(inputId),
    onValid: this.onInputValid(inputId),
    onError: this.onInputError(inputId),
    T: this.props.T,
  });

  onBirthdayUpdate = (birthday) => {
    this.setState({ birthdaySetByUser: true, birthday });
  };

  render() {
    const {
      T,
      location,
      user,
      keyboardOpen,
      appStyles,
      pageContext: {
        branches,
        businessAppConfiguration: { 
          allowAnyName,
          askForPreferredLocations,
          requirePreferredLocationsOnSignup,
        },
      },
    } = this.props;

    const { granted, birthdaySetByUser } = this.state;
    const nativeMode =
      typeof window !== "undefined" ? window.isNativeApp : false;
    const changePhotoMode = Boolean(getParams(location).changePhoto);
    const birthday =
      (birthdaySetByUser
        ? this.state.birthday
        : _.get(user, "userDetails.data.birthday")) || {};

    if (user.userDetails.loading) {
      return (
        <AppContainer.Content tightBottom appStyles={appStyles}>
          <AppContainer.CenteredColumn>
            <Loader appStyles={appStyles} classNames={styles.Loader} />
            <strong>{T("Loading profile...")}</strong>
          </AppContainer.CenteredColumn>
        </AppContainer.Content>
      );
    }

    return (
      <>
        {user.loggedIn && changePhotoMode ? (
          <AppContainer.Content
            appStyles={appStyles}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <div>
              {this.state.preview ? (
                <img
                  className={styles.PreviewDefault}
                  src={this.state.preview}
                />
              ) : (
                <div className={styles.PreviewDefault} />
              )}
            </div>
            {nativeMode ? (
              granted ? (
                <Avatar
                  onCrop={this.onCropPicture}
                  onClose={this.onClosePicture}
                  height={250}
                  width={250}
                />
              ) : (
                <PhotoUploader
                  T={T}
                  appStyles={appStyles}
                  onChange={this.uploadImage}
                  image={this.state.preview}
                />
              )
            ) : (
              <Avatar
                onCrop={this.onCropPicture}
                onClose={this.onClosePicture}
                height={250}
                width={250}
              />
            )}
            <AppContainer.Footer
              relativePosition={keyboardOpen}
              ref={(el) => {
                this.footer = el;
              }}
              center
              height={true}
            >
              <Button
                onClick={this.applyPhotoEdit}
                appStyles={appStyles}
                centered
              >
                {T("Save Photo")}
              </Button>
            </AppContainer.Footer>
          </AppContainer.Content>
        ) : (
          <AppContainer.Content
            appStyles={appStyles}
            classNames={styles.DesktopGutters}
          >
            <div
              style={{
                display: "flex",
                margin: "16px 16px 0 16px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className={styles.ProfilePicture}>
                {this.state.preview ? (
                  <img
                    className={styles.PreviewDefault}
                    src={this.state.preview}
                  />
                ) : user.loggedIn && user.userDetails.data.avatarImage ? (
                  <img
                    className={styles.PreviewDefault}
                    src={user.userDetails.data.avatarImage.url}
                  />
                ) : (
                  <EmptyProfileIcon
                    className={styles.EmptyProfilePicture}
                  />
                )}
              </div>
              <div>
                <Button
                  appStyles={appStyles}
                  onClick={this.handleChangeToCamera("changePhoto")}
                  centered
                >
                  {T("Change Photo")}
                </Button>
              </div>
            </div>
            <Card appStyles={appStyles}>
              <Card.Title
                light
                style={{ textShadow: "1px 1px 0 0 rgba(0,0,0,0.8)" }}
                appStyles={appStyles}
              >
                {T("Personal Details")}
              </Card.Title>
              <Card.Content>
                <InputGroup tight appStyles={appStyles} T={T}>
                  <div style={{ marginBottom: 10 }}>{T("Full Name")}</div>
                  <FullNameInput
                    allowAnyName={allowAnyName}
                    {...this.getInputPropsFor(NAME)}
                    appStyles={appStyles}
                    initialValue={this.state.name || ""}
                    placeholder={T("Full Name")}
                  />
                  <div style={{ marginBottom: 10 }}>
                    {T("Email Address")}
                  </div>
                  <EmailInput
                    {...this.getInputPropsFor(EMAIL)}
                    appStyles={appStyles}
                    initialValue={this.state.email || ""}
                    placeholder={T("Email Address")}
                  />
                  {user.loggedIn &&
                  !this.state.birthdaySetByUser &&
                  !_.isEmpty(birthday) ? (
                    <div style={{ marginBottom: 10 }}>
                      <div style={{ marginBottom: 10 }}>
                        {T("Birthday")}
                      </div>
                      <div
                        className={classnames(
                          styles.DateInput,
                          appStyles.rtl && styles.RTL
                        )}
                        style={appStyles.Input}
                      >
                        <BirthdayIcon />
                        <div style={{ margin: "0 10px", color: "inherit" }}>
                          {moment(
                            new Date(
                              birthday.year,
                              birthday.month - 1,
                              birthday.day
                            )
                          ).format(
                            birthday.year === 1900
                              ? "ddd, MMM D"
                              : "ddd, MMM D YYYY"
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <BirthdayDatePicker
                      T={T}
                      appStyles={appStyles}
                      {...this.getInputPropsFor(BIRTHDAY, "ref")}
                      containerMarginBottom={100}
                      onChange={this.onBirthdayUpdate}
                      birthday={birthday || {}}
                    />
                  )}
                  {askForPreferredLocations && (
                    <PreferredLocations
                      T={T}
                      style={{ marginBottom: 10 }}
                      required={requirePreferredLocationsOnSignup}
                      appStyles={appStyles}
                      availableLocations={branches.filter(
                        ({ isDisplayed }) => isDisplayed
                      )}
                      value={this.state.preferredLocations}
                      label={
                        <Typography
                          style={{ marginBottom: 8 }}
                          variant="subtitle1"
                        >
                          {T(`Preferred locations:`)}
                        </Typography>
                      }
                      errorMessage={T(this.state.preferredLocations_ERROR)}
                      onChange={(preferredLocations) =>
                        this.setState({ preferredLocations }, () => {
                          const {
                            error,
                          } = preferredLocationsSchema.validate(
                            preferredLocations
                          );
                          if (error) {
                            this.setState({
                              preferredLocations_ERROR:
                                "Please select at least one location",
                            });
                          } else {
                            this.setState({
                              preferredLocations_ERROR: null,
                            });
                          }
                        })
                      }
                    />
                  )}
                </InputGroup>
              </Card.Content>
            </Card>
            {!user.profileSignUpFlowMode && (
              <Card appStyles={appStyles}>
                <Card.Title
                  light
                  style={{ textShadow: "1px 1px 0 0 rgba(0,0,0,0.8)" }}
                  appStyles={appStyles}
                >
                  {T("My Stats")}
                </Card.Title>
                <Card.Content
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: appStyles.showAllProfileStatistics
                      ? "space-between"
                      : "center",
                  }}
                >
                  {!appStyles.hideVisitCount && (
                    <AppContainer.CenteredColumn>
                      <div
                        className={styles.Stats}
                        style={{ background: appStyles.accentColor }}
                      >
                        {user.loggedIn
                          ? user.userDetails.data.numberOfVisits
                          : 0}
                      </div>
                      <div style={{ marginTop: 10 }}>{T("Visits")}</div>
                    </AppContainer.CenteredColumn>
                  )}
                  {appStyles.showAllProfileStatistics && (
                    <AppContainer.CenteredColumn>
                      <div
                        className={styles.Stats}
                        style={{ background: appStyles.accentColor }}
                      >
                        {user.loggedIn
                          ? user.userDetails.data.numberOfDonations
                          : 0}
                      </div>
                      <div style={{ marginTop: 10 }}>{T("Donations")}</div>
                    </AppContainer.CenteredColumn>
                  )}
                  {appStyles.showAllProfileStatistics && (
                    <AppContainer.CenteredColumn>
                      <div
                        className={styles.Stats}
                        style={{ background: appStyles.accentColor }}
                      >
                        {user.loggedIn
                          ? user.userDetails.data.numberOfShares
                          : 0}
                      </div>
                      <div style={{ marginTop: 10 }}>{T("Shares")}</div>
                    </AppContainer.CenteredColumn>
                  )}
                </Card.Content>
              </Card>
            )}

            {appStyles.showTastePreferences && (
              <Card appStyles={appStyles}>
                <Card.Title
                  light
                  style={{ textShadow: "1px 1px 0 0 rgba(0,0,0,0.8)" }}
                  appStyles={appStyles}
                >
                  {T("Taste Preferences")}
                </Card.Title>
                <Card.Content>
                  <Card.Content.CheckboxGroup
                    horizontal
                    checked={this.state.checkedPreferences}
                    onChange={this.onChange}
                    appStyles={appStyles}
                  >
                    {_.map(
                      appStyles.tastePrefrencesOptions,
                      (tastePreference) => (
                        <Card.Content.CheckboxOption
                          key={tastePreference}
                          iconSize={16}
                          iconInnerSize={16}
                          pointColor={appStyles.actionColor}
                          label={T(labelTastePreference(tastePreference))}
                          selectedChildrenClassName={styles.SelectedIcon}
                          value={tastePreference}
                        >
                          <div className={styles.IconGroupWrapper}>
                            <CircleIcon className={styles.BackgroundIcon} />
                            <div className={styles.ForegroundIconWrapper}>
                              {renderTastePreferenceIcon(tastePreference)}
                            </div>
                          </div>
                        </Card.Content.CheckboxOption>
                      )
                    )}
                  </Card.Content.CheckboxGroup>

                  <p style={{ textAlign: "center", fontSize: "1rem" }}>
                    {T(
                      "While this info will help us serve you in our restaurants, there is no substitute for direct communication with us during your visit. So, please be sure to alert our team if you have any food allergies."
                    )}
                  </p>
                </Card.Content>
              </Card>
            )}

            {!this.hideSaveButton() && (
              <AppContainer.Footer
                relativePosition={keyboardOpen}
                ref={(el) => {
                  this.footer = el;
                }}
                appStyles={appStyles}
                transparentGradient
                center
              >
                <AppContainer.Footer.Button
                  onClick={this.saveProfile}
                  appStyles={appStyles}
                  loading={user.saveProfileState.sending}
                  completed={user.saveProfileState.sent}
                >
                  {T("Save")}
                </AppContainer.Footer.Button>
              </AppContainer.Footer>
            )}
          </AppContainer.Content>
        )}
      </>
    );
  }
}
